import React, { useState, useEffect } from "react";
import { AppStore } from "../../stores/AppStore";
import { useStyles } from "../styling/global/global";
import { Sensor } from "../../services/FunctionService";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { Select as AntSelect } from "antd";
import "antd/dist/antd.css";

const useStyle = makeStyles({
  paper: {
    border: "2px solid #6D809D",
    color: "#15325F",
  },
});

interface Props {
  appStore?: AppStore;
  setLoading: (b: boolean) => void;
  setSiteId: (id: number) => void;
  setSensorId: (id: any) => void;
  sensorId: number;
  siteids: number[];
  loading: boolean;
  selectAllSites: boolean;
  title?: string;
  multi?: boolean;
  ignoreHubs?: boolean;
}

const ITEM_HEIGHT = 88;
const ITEM_PADDING_TOP = 0;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      minHeight: 0,
      maxWidth: 500,
      minWidth: 0,
      color: "#15325F",
    },
  },
};

const CameraList: React.FC<Props> = ({
  appStore,
  setSiteId,
  setLoading,
  sensorId,
  siteids,
  setSensorId,
  loading,
  selectAllSites,
  title,
  multi = false,
  ignoreHubs = false,
}) => {
  const [sensorList, setSensorList] = React.useState<any[]>([]);
  const [sensorListNames, setSensorListNames] = React.useState<Sensor[]>([]);
  const [sensor, setSensor] = React.useState<Sensor | string[]>();
  const [sensorname, setName] = useState("Please Select Camera");
  const [selectedOpts, setSelectedOpts] = React.useState<any>([]);
  const [selectedCameras, setSelectedCameras] = React.useState([]);

  const classes = useStyles();
  const styler = useStyle();

  const handleOptChange = (value: any, option: any) => {
    setSelectedCameras(
      option.map((item: any) => {
        return item.value;
      })
    );
    setSensorId(value);
    // return setSelectedOpts(value);
  };

  const handleChange = (event: React.ChangeEvent<{ value: any }>) => {
    let id = +event.target.value;
    setSensorId(id as number);
  };

  const handleChangeMultiple = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setSensorListNames(event.target.value as Sensor[]);
    setSensorId(event.target.value);
  };

  // Fetch sensors
  useEffect(() => {
    const fetchData = async () => {
      if (selectAllSites) {
        return setSensorList([]);
      }
      setLoading(true);
      var token = await appStore?.authService.getAuthorisedToken();
      if (token && siteids.length > 0) {
        try {
          let sensors_array: Sensor[] = [];
          for (let siteid of siteids) {
            var hubs = await appStore?.functionService.getHubsForSite(
              token,
              siteid
            );
            if (hubs) {
              var sensors: Sensor[] =
                await appStore?.functionService.getSensorsForSite(
                  token,
                  siteid,
                  "thumbnail"
                );
              sensors_array.push(...sensors);
            }
          }
          if (!multi) {
            setSensorList(sensors_array);
          } else {
            setSensorList(
              sensors_array.map((sensor: Sensor) => {
                return { label: sensor.sensor_id_str, value: sensor.sensor_id };
              })
            );
          }

          setName("Please Select Camera");
        } catch (err) {
          return err;
        } finally {
          setLoading(false);
          setSensor(undefined);
        }
      } else {
        setSensorList([]);
        setName("Please Select Camera");
        setLoading(false);
      }
    };
    fetchData();
  }, [siteids]);

  if (multi) {
    return (
      // <FormControl
      //   variant="outlined"
      //   style={{
      //     width: 360,
      //     color: "#6D809D",
      //     marginTop: 0,
      //     marginLeft: 3,
      //     backgroundColor: "white",
      //   }}
      // >
      //   <InputLabel
      //     id="demo-simple-select-outlined-label"
      //     style={{
      //       color: "#15325F",
      //       fontSize: 12,
      //       fontWeight: "bold",
      //       padding: 0,
      //     }}
      //   >
      //     {title ? title : "Filter by camera"}
      //   </InputLabel>
      //   <Select
      //     labelId="demo-simple-select-outlined-label"
      //     id="demo-simple-select-outlined"
      //     multiple
      //     value={sensorListNames}
      //     onChange={handleChangeMultiple}
      //     MenuProps={MenuProps}
      //   >
      //     {sensorList.map((sensor: Sensor) => (
      //       <MenuItem value={sensor.sensor_id}>
      //         {sensor.sensor_id_str} - {sensor.human_name}
      //       </MenuItem>
      //     ))}
      //   </Select>
      // </FormControl>
      <AntSelect
        mode={"multiple"}
        loading={loading}
        maxTagCount={2}
        value={selectedCameras}
        allowClear={true}
        bordered={true}
        style={{ width: 360, color: "#15325f", borderRadius: 4, marginLeft: 3 }}
        placeholder={title ? title : "Filter by camera"}
        onChange={handleOptChange}
        options={sensorList}
        optionFilterProp="label"
      />
    );
  }

  return (
    <FormControl
      variant="outlined"
      style={{
        width: 360,
        color: "#6D809D",
        marginTop: 0,
        marginLeft: 3,
        backgroundColor: "white",
      }}
    >
      <InputLabel
        id="demo-simple-select-outlined-label"
        style={{
          color: "#15325F",
          fontSize: 12,
          fontWeight: "bold",
          padding: 0,
        }}
      >
        {title ? title : "Select camera"}
      </InputLabel>
      <Select
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        value={sensor}
        onChange={handleChange}
        label="Age"
        MenuProps={MenuProps}
      >
        <MenuItem value={-1}>
          <span style={{ width: 260 }}> All cameras</span>
        </MenuItem>
        {sensorList.map((sensor: Sensor) => (
          <MenuItem value={sensor.sensor_id}>
            <span style={{ width: 260 }}>
              {sensor.sensor_id_str} - {sensor.human_name}
            </span>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CameraList;
