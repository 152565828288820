import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import { useStyles } from "../../styling/global/global";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Spinner from "../../../spinner/Spinner";
import CustomModal from "../../UI/CustomModal";

import Switch from "@material-ui/core/Switch";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import ButtonGroup from "@material-ui/core/ButtonGroup";
import Typography from "@material-ui/core/Typography";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import Checkbox from "@material-ui/core/Checkbox";
import { AppStore } from "../../../stores/AppStore";
import { getBaseUrl } from "../../HelperFunctions";
import { ReportGroup, Site } from "../../../services/FunctionService";

import Dialog, { DialogProps } from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import { ArrayToStringifiedArray } from "../../../hooks/ArrayToStringifiedArray";

import {
  createStyles,
  Theme,
  useTheme,
  withStyles,
} from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

// Switch Styles
const AntSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 28,
      height: 16,
      padding: 0,
      display: "flex",
    },
    switchBase: {
      padding: 2,
      color: theme.palette.grey[500],
      "&$checked": {
        transform: "translateX(12px)",
        color: theme.palette.common.white,
        "& + $track": {
          opacity: 1,
          backgroundColor: theme.palette.primary.main,
          borderColor: theme.palette.primary.main,
        },
      },
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: "none",
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: theme.palette.common.white,
    },
    checked: {},
  })
)(Switch);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
      "&:hover": {
        backgroundColor: "#e3f2fd",
      },
    },
  })
)(TableRow);

interface Report {
  report_id: number;
  site_ids: number[];
  report_group_id: number;
  site_product_details: { site_id: number; site_product_name: string }[];
  report_type: string;
  config: {
    time: string;
    isoweekday: number;
    include_escalated_events?: boolean;
    include_csv?: boolean;
  };
  timezone_str: string;
}

interface ReportEditProps {
  reloadOrgs: () => void;
  open: boolean;
  setOpenToClose: () => void;
  appStore?: AppStore;
  report?: Report;
  report_tree: any;
  groupTreeName: string;
  top_org_only: boolean;
  groupid: number;
  editOrgTreeName: string;
}

function getStyles(name: string, personName: string[], theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const time_zones = [
  "Africa/Johannesburg",
  "Africa/Cairo",
  "Africa/Nairobi",
  "Africa/Accra",
  "Africa/Harare",
  "America/New_York",
  "America/Los_Angeles",
  "America/Indiana/Indianapolis",
  "America/Detroit",
  "America/Phoenix",
  "Asia/Shanghai",
  "Asia/Riyadh",
  "Asia/Colombo",
  "Asia/Calcutta",
  "Asia/Tokyo",
  "Australia/Sydney",
  "Australia/Melbourne",
  "Australia/Darwin",
  "Australia/Hobar",
  "Europe/Athens",
  "Europe/Berlin",
  "Europe/Helsinki",
  "Europe/London",
  "Europe/Paris",
  "Pacific/Auckland",
  "Pacific/Chatham",
  "UTC",
];
const hrs_list = [
  "00",
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
];

const min_list = [
  "00",
  "05",
  "10",
  "15",
  "20",
  "25",
  "30",
  "35",
  "40",
  "45",
  "50",
  "55",
];

const ReportEdit: React.FC<ReportEditProps> = ({
  open,
  setOpenToClose,
  appStore,
  report,
  report_tree,
  reloadOrgs,
  groupTreeName,
  top_org_only,
  groupid,
  editOrgTreeName,
}) => {
  let [siteIds, setSiteIds] = React.useState<number[]>(
    report?.site_ids ? report?.site_ids : []
  );
  const [selectAll, setSelectAll] = React.useState(false);
  const [unSelectAll, setUnSelectAll] = React.useState(false);
  const [loadingSites, setLoadingSites] = React.useState(false);

  const [maxWidth] = React.useState<DialogProps["maxWidth"]>("md");
  const [siteList, setSiteList] = React.useState<any>([]);
  const [loading, setLoading] = React.useState(false);
  const [orgslist, setOrgsList] = React.useState<any>([]);
  const [openModal, setOpenModal] = React.useState(false);
  const [modalMessage, setModalMessage] = React.useState<any>(
    "Successfully updated report configurations"
  );

  const [sitePushedIds, setSitePushedIds] = React.useState<number[] | any[]>(
    []
  );
  const [productNames, setProductNames] = React.useState<
    { name: string; id: number }[]
  >([]);
  const [selectedProductId, setSelectedProductId] = React.useState<
    unknown | string
  >("loading...");

  const [initialProductId, setInitialProductId] = React.useState<
    unknown | string
  >("loading...");

  const [reportType, setReportType] = React.useState<string | undefined>(
    report?.report_type.split(":")[0]
  );

  const [selectedPeriodValue, setSelectedPeriodValue] = React.useState(
    report?.config.isoweekday
      ? "weekly"
      : report?.config.time
      ? "daily"
      : "monthly"
  );
  const [selectedHour, setSelectedHour] = React.useState<string | unknown>(
    (report?.report_type && report?.report_type.includes("daily")) ||
      report?.report_type.includes("weekly")
      ? report.config.time.split(":")[0]
      : "00"
  );
  const [selectedMinutes, setSelectedMinutes] = React.useState<
    string | unknown
  >(
    (report?.report_type && report?.report_type.includes("daily")) ||
      report?.report_type.includes("weekly")
      ? report.config.time.split(":")[1]
      : "00"
  );
  const [escalatedEvents, setescalatedEvents] = React.useState(
    report?.config.include_escalated_events
      ? report?.config.include_escalated_events
      : false
  );
  const [personName] = React.useState<string[]>([]);
  const [zone, setZone] = React.useState<any>(
    report?.timezone_str !== "UTC" ? report?.timezone_str : "0"
  );
  const [inc_csv, setIncCSV] = React.useState(
    report?.config.include_csv && report?.config.include_csv == true
      ? true
      : false
  );

  const [selectedDate, setSelectedDate] = React.useState<
    number | string | undefined
  >(report?.config.isoweekday ? report?.config.isoweekday : 0);
  const [err, setErr] = React.useState(false);
  const [message, setMessage] = React.useState("");

  const [reportgroupsForWholeTree, setReportGroupsForWholeTree] = useState<
    ReportGroup[] | undefined
  >(report_tree);

  const [selectedReport, setSelectedReport] = React.useState<unknown>(
    report?.report_group_id
  );

  const [day, setDay] = React.useState({
    sun: report?.config.isoweekday === 7 ? true : false,
    mon: report?.config.isoweekday === 1 ? true : false,
    tue: report?.config.isoweekday === 2 ? true : false,
    wed: report?.config.isoweekday === 3 ? true : false,
    thur: report?.config.isoweekday === 4 ? true : false,
    fri: report?.config.isoweekday === 5 ? true : false,
    sat: report?.config.isoweekday === 6 ? true : false,
  });

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  // STYLING SECTION
  const classes = useStyles();
  const theme = useTheme();

  const handleClose = () => {
    setOpenToClose();
  };

  const handlePeriodChange = (
    e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    if (reportType === "enhanced_camera_health_report") {
      setSelectedPeriodValue("daily");
    }

    if (e.target.value === "daily") {
      setSelectedPeriodValue("daily");
    }

    if (e.target.value === "weekly") {
      setSelectedPeriodValue("weekly");
    }

    if (e.target.value === "monthly") {
      setSelectedPeriodValue("monthly");
    }
  };

  const siteIdsHandler = (
    id: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let status = event.target.checked;
    let newItem = id;
    if (status) {
      siteIds.push(newItem);
    } else {
      const index = siteIds.indexOf(id);
      if (index > -1) {
        siteIds.splice(index, 1);
      }
    }

    let uniqueIds = siteIds.filter((c, index) => {
      return siteIds.indexOf(c) === index;
    });
  };

  const selectAllHandler = () => {
    setSiteIds(sitePushedIds);
    setSelectAll(true);
    setUnSelectAll(false);
  };

  //Unselect All Function
  const unSelectAllHandler = () => {
    setUnSelectAll(true);
    setSelectAll(false);
    setSiteIds([]);
  };

  const handleChangeDay = (day: string) => {
    let default_day = {
      sun: false,
      mon: false,
      tue: false,
      wed: false,
      thur: false,
      fri: false,
      sat: false,
    };

    if (day === "sun") {
      default_day.sun = true;
      setDay(default_day);
      setSelectedDate(7);
    }
    if (day === "mon") {
      default_day.mon = true;
      setDay(default_day);
      setSelectedDate(1);
    }
    if (day === "tue") {
      default_day.tue = true;
      setDay(default_day);
      setSelectedDate(2);
    }
    if (day === "wed") {
      default_day.wed = true;
      setDay(default_day);
      setSelectedDate(3);
    }
    if (day === "thur") {
      default_day.thur = true;
      setDay(default_day);
      setSelectedDate(4);
    }
    if (day === "fri") {
      default_day.fri = true;
      setDay(default_day);
      setSelectedDate(5);
    }
    if (day === "sat") {
      default_day.sat = true;
      setDay(default_day);
      setSelectedDate(6);
    }
  };

  const handleTypeOfReport = (value: string) => {
    setReportType(value);
  };

  const onSubmitHandler = () => {
    let configs: any = {
      include_escalated_events: escalatedEvents,
      include_csv: inc_csv,
    };
    if (
      selectedPeriodValue.includes("week") &&
      reportType === "site_activity"
    ) {
      configs = {
        time: `${selectedHour}:${selectedMinutes}`,
        isoweekday: selectedDate,
        include_escalated_events: escalatedEvents,
        include_csv: inc_csv,
      };

      if (selectedDate === 0) {
        return console.log(
          "Please select the day you want to receive reports!"
        );
      }
    }

    if (
      selectedPeriodValue.includes("daily") &&
      reportType === "site_activity"
    ) {
      configs = {
        time: `${selectedHour}:${selectedMinutes}`,
        include_escalated_events: escalatedEvents,
        include_csv: inc_csv,
      };
    }

    if (
      selectedPeriodValue.includes("week") &&
      reportType === "site_activity_consolidated"
    ) {
      configs = {
        time: `${selectedHour}:${selectedMinutes}`,
        isoweekday: selectedDate,
        include_escalated_events: escalatedEvents,
        include_csv: inc_csv,
      };
    }

    if (
      selectedPeriodValue.includes("daily") &&
      reportType === "enhanced_camera_health_report"
    ) {
      configs = {
        time: `${selectedHour}:${selectedMinutes}`,
        isoweekday: selectedDate,
        include_escalated_events: escalatedEvents,
        include_csv: inc_csv,
      };
    }

    if (
      selectedPeriodValue.includes("weekly") &&
      reportType === "idle_camera_report"
    ) {
      configs = {
        time: `${selectedHour}:${selectedMinutes}`,
        isoweekday: selectedDate,
        include_escalated_events: escalatedEvents,
        include_csv: inc_csv,
      };
    }
    if (
      selectedPeriodValue.includes("weekly") &&
      reportType === "site_activity_group"
    ) {
      configs = {
        time: `${selectedHour}:${selectedMinutes}`,
        isoweekday: selectedDate,
        include_escalated_events: escalatedEvents,
        include_csv: inc_csv,
      };
    }

    if (
      selectedPeriodValue.includes("daily") &&
      reportType === "site_activity_group"
    ) {
      configs = {
        time: `${selectedHour}:${selectedMinutes}`,
        include_escalated_events: escalatedEvents,
        include_csv: inc_csv,
      };
    }

    if (
      selectedPeriodValue.includes("daily") &&
      reportType === "site_activity_consolidated"
    ) {
      configs = {
        time: `${selectedHour}:${selectedMinutes}`,
        include_escalated_events: escalatedEvents,
        include_csv: inc_csv,
      };
    }

    if (selectedPeriodValue.includes("week") && reportType === "site_health") {
      configs = {
        time: `${selectedHour}:${selectedMinutes}`,
        isoweekday: selectedDate,
      };

      if (selectedDate === 0) {
        return console.log("Please select the day you want to receive reports");
      }
    }

    if (selectedPeriodValue.includes("daily") && reportType === "site_health") {
      configs = {
        time: `${selectedHour}:${selectedMinutes}`,
      };
    }

    if (
      selectedPeriodValue.includes("monthly") &&
      reportType === "site_health"
    ) {
      configs = {};
    }

    updateReport(configs);
  };

  const updateReport = async (configs: any) => {
    // setLoading(true);
    setLoadingSites(true);
    let baseUrl = getBaseUrl();
    let token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      try {
        let request = JSON.stringify({
          report_id: report!.report_id,
          report_group_id: selectedReport,
          report_type: reportType + ":" + selectedPeriodValue,
          config: configs,
          timezone_str: zone,
        });

        if (reportType === "site_activity_consolidated") {
          if (siteIds.length === 0) {
            setModalMessage("Please select at least one site to update  ");
            setLoadingSites(false);
            return setOpenModal(true);
          }
          request = JSON.stringify({
            report_id: report!.report_id,
            report_group_id: selectedReport,
            report_type: reportType + ":" + selectedPeriodValue,
            config: configs,
            timezone_str: zone,
            site_ids: ArrayToStringifiedArray(siteIds),
          });
        }

        const res = await fetch(baseUrl + "/site_report", {
          method: "PUT",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: request,
        });

        const result = (await (await res).json()) as any;
        let msg = result.msg;

        if (!res.ok) {
          // setErr(true);
          // setMessage("Something went wrong, please try again");
          if (msg.startsWith("no changes detected from user")) {
            msg = "No changes detected in the report configuration update";
          }
          setModalMessage(msg);
          setOpenModal(true);
          setLoadingSites(false);
          return;
        }

        if (result.success) {
          setModalMessage("Successfully updated report!");
          setOpenModal(true);
        }

        if (!result.success) {
          setMessage(msg);
        }
      } catch (err) {
        console.log(err);
      }
      setLoading(false);
      setLoadingSites(false);
      reloadOrgs();
      handleClose();
    }
  };

  const fetchOrgsData = async () => {
    setLoading(true);
    var token = await appStore?.authService.getAuthorisedToken();
    var baseUrl = getBaseUrl();
    if (token) {
      try {
        const request = JSON.stringify({
          enabled: true,
          paginate: false,
          verbose: false,
          incl_tree_names: true,
        });

        const res = fetch(baseUrl + "/list_orgs", {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: request,
        });
        const result = await (await res).json();
        const orglist = result;
        if (orglist && orglist.success) {
          let orgz = orglist?.orgs;
          orgz.sort(function (a: any, b: any) {
            return a.org_tree_names - b.org_tree_names;
          });
          orgz.sort(function (a: any, b: any) {
            return a.org_tree_names.length - b.org_tree_names.length;
          });
          setOrgsList(orgz);
          setLoading(false);
        }
      } catch (err) {
        return err;
      }
    }
  };

  useEffect(() => {
    fetchOrgsData();
  }, []);

  // Fetch sites
  useEffect(() => {
    const fetchData = async () => {
      setLoadingSites(true);
      var token = await appStore?.authService.getAuthorisedToken();
      var baseUrl = getBaseUrl();
      if (
        reportType !== "site_activity_group" &&
        reportType !== "idle_camera_report"
      ) {
        if (
          token &&
          selectedProductId &&
          report!.site_product_details[0].site_product_name &&
          productNames
        ) {
          try {
            const request = JSON.stringify({
              enabled: true,
              top_org_id: groupid,
              paginate: false,
              top_org_only: false,
              product_name_id: productNames.filter(
                (item) => item.name === selectedProductId
              )[0].id,
              // product_name_id: productNames.filter((item) => item.name === report!.site_product_details[0].site_product_name)[0].id
            });

            const res = fetch(baseUrl + "/list_sites", {
              method: "post",
              headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
                "Content-Type": "application/json",
              },
              body: request,
            });
            const result = await (await res).json();
            if (result && result.success) {
              let sites: Site[] = result?.sites;
              sites.sort(function (a: Site, b: Site) {
                // return a.site_name.toLowerCase() > b.site_name.toLowerCase();
                if (b.site_name.toLowerCase() > a.site_name.toLowerCase()) {
                  return -1;
                }
                if (b.site_name.toLowerCase() < a.site_name.toLowerCase()) {
                  return 1;
                }
                return 0;
              });
              setSiteList((prev: Site) => [...sites]);

              let pushids: number[] = [];
              sites.forEach((site: Site) => {
                pushids.push(site.site_id);
              });

              if (!selectAll && unSelectAll) {
                setSiteIds([]);
              }
              if (selectAll && !unSelectAll) {
                setSiteIds(pushids);
              }
              setSitePushedIds(pushids);
            }
            setLoadingSites(false);
          } catch (err) {
            return err;
          } finally {
            setLoadingSites(false);
          }
        }
      }
    };
    fetchData();
  }, [selectedProductId, selectAll, unSelectAll, productNames]);

  //Table Content Setup
  var sites_display: React.ReactNode[] = [];
  if (siteList && siteList.length > 0)
    siteList.forEach((site: { [key: string]: any }, index: any) => {
      if (site.public_product_name !== selectedProductId) {
        return;
      }

      let group = orgslist.find((x: any) => x.org_id === site.org_id);
      let rpl;

      let selected = true;
      let indx = report?.site_ids.findIndex((x: number) => x === site.site_id);

      if (indx && indx < 0) {
        selected = false;
      }
      if (group) {
        let tree = group.org_tree_names!.map((item: any) => `   /   ${item} `);
        let treeString = tree.toString();
        rpl = treeString.replace(/,/g, "").replace("/", "");
      }
      sites_display.push(
        <StyledTableRow>
          <TableCell>
            <Typography className={classes.bold_text}>
              {site.site_id}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography className={classes.bold_text}>
              {rpl}
              {/* {groupTreeName} */}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography className={classes.bold_text}>
              {site.site_name}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography className={classes.bold_text}>
              {site.public_product_name}
            </Typography>
          </TableCell>
          <TableCell>
            <Grid component="label" container alignItems="center" spacing={1}>
              <Tooltip
                placement="bottom"
                title="select site for scheduled reports"
              >
                <Grid item>
                  <AntSwitch
                    defaultChecked={
                      selectAll && !unSelectAll
                        ? true
                        : !selectAll && unSelectAll
                        ? false
                        : selected
                    }
                    // defaultChecked={selected}
                    // checked={selected}
                    onChange={(e) => {
                      siteIdsHandler(site.site_id, e);
                    }}
                    name="checkedC"
                  />
                </Grid>
              </Tooltip>
            </Grid>
          </TableCell>
        </StyledTableRow>
      );
    });

  const fetchProductNames = async () => {
    setLoading(true);
    var token = await appStore?.authService.getAuthorisedToken();
    var baseUrl = getBaseUrl();
    if (token) {
      try {
        const request = JSON.stringify({
          top_org_id: groupid,
          top_org_only: false,
          enabled: true,
        });

        const res = fetch(baseUrl + "/list_site_products", {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: request,
        });
        const result = await (await res).json();
        if (result && result.success) {
          setProductNames(result.public_product_names);
          setLoading(false);
        }
      } catch (err) {
        return err;
      }
    }
  };

  useEffect(() => {
    fetchProductNames();
  }, []);

  useEffect(() => {
    if (
      reportType !== "site_activity_group" &&
      reportType !== "idle_camera_report"
    ) {
      setSelectedProductId(
        report?.site_product_details
          ? report!.site_product_details[0].site_product_name
          : ""
      );
      setInitialProductId(
        report?.site_product_details
          ? report!.site_product_details[0].site_product_name
          : ""
      );
    } else {
      setSelectedProductId("");
      setInitialProductId("");
    }
  }, []);

  return (
    <div>
      <Dialog
        fullWidth={false}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <div
          style={{
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <h2
            className={classes.bold_text}
            style={{ marginBottom: 25, marginTop: 25 }}
          >
            Edit Report Configurations for ({editOrgTreeName.replace("/", "")})
          </h2>
          <DialogContent>
            <div>
              <CustomModal
                closeHandler={() => {
                  setOpenModal(false);
                }}
                open={openModal}
              >
                <p className={classes.bold_text}>{modalMessage} &nbsp;&nbsp;</p>
              </CustomModal>
              <div style={{ width: 250, color: "#15325F", marginRight: 150 }}>
                <h2 className={classes.header_text}>Type of Report:</h2>
                <div style={{ width: 250, color: "#15325F", marginLeft: 10 }}>
                  <FormControl fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={reportType}
                      label="Report Type"
                      onChange={(e) =>
                        handleTypeOfReport(e.target.value as string)
                      }
                    >
                      <MenuItem
                        value={report?.report_type.split(":")[0]}
                        key={report?.report_type.split(":")[0]}
                      >
                        {report?.report_type.split(":")[0].replaceAll("_", " ")}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
              {reportgroupsForWholeTree &&
                reportType !== "site_activity_group" &&
                reportType !== "idle_camera_report" && (
                  <div
                    style={{
                      color: "#15325F",
                      marginRight: 150,
                      marginTop: 50,
                      marginBottom: 25,
                    }}
                  >
                    <h2 className={classes.header_text}>Select Product Name</h2>
                    <div
                      style={{
                        width: 250,
                        color: "#15325F",
                        marginLeft: 10,
                      }}
                    >
                      <FormControl
                        fullWidth
                        disabled={
                          reportType === "enhanced_camera_health_report" ||
                          reportType === "site_activity_group" ||
                          reportType === "idle_camera_report"
                        }
                      >
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Report Type"
                          value={
                            reportType === "enhanced_camera_health_report"
                              ? "all"
                              : reportType === "site_activity_group"
                              ? ""
                              : selectedProductId
                          }
                          onChange={(e) => {
                            if (e.target.value === initialProductId) {
                              setSiteIds(report!.site_ids);
                            } else {
                              setSiteIds([]);
                            }
                            setSelectedProductId(e.target.value);
                          }}
                        >
                          {reportType === "enhanced_camera_health_report" ? (
                            <MenuItem key="all" value="all">
                              All
                            </MenuItem>
                          ) : reportType === "site_activity_group" ||
                            reportType === "idle_camera_report" ? (
                            <MenuItem key="all" value="all">
                              {""}
                            </MenuItem>
                          ) : reportType === "site_activity_consolidated" ? (
                            productNames!.map((product) => (
                              <MenuItem
                                key={product.id}
                                value={product.name}
                                style={getStyles(
                                  product.name,
                                  personName,
                                  theme
                                )}
                              >
                                {product.name}
                              </MenuItem>
                            ))
                          ) : (
                            <MenuItem
                              value={
                                report!.site_product_details[0]
                                  .site_product_name
                              }
                              style={getStyles(
                                report!.site_product_details[0]
                                  .site_product_name,
                                personName,
                                theme
                              )}
                            >
                              {
                                report!.site_product_details[0]
                                  .site_product_name
                              }
                            </MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                )}
              {!!reportgroupsForWholeTree && (
                <div
                  style={{
                    display: "flex",
                    marginTop: 25,
                    color: "#15325F",
                    alignItems: "center",
                    marginBottom: 30,
                  }}
                >
                  <div
                    style={{
                      color: "#15325F",
                      marginRight: 150,
                      marginTop: 10,
                      marginBottom: 25,
                    }}
                  >
                    <h2 className={classes.header_text}>Select Report Group</h2>
                    <div
                      style={{
                        width: 250,
                        color: "#15325F",
                        marginLeft: 10,
                      }}
                    >
                      <FormControl fullWidth>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Report Type"
                          defaultValue={report!.report_group_id}
                          value={selectedReport}
                          onChange={(e) => {
                            setSelectedReport(e.target.value);
                          }}
                        >
                          {reportgroupsForWholeTree!.map((report) => (
                            <MenuItem
                              key={report.id}
                              value={report.id}
                              style={getStyles(report.name, personName, theme)}
                            >
                              {report.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </div>
              )}
              <div style={{ marginTop: 30, color: "white" }} />
              <div
                style={{
                  // marginTop: 25,
                  color: "#15325F",
                  marginBottom: 40,
                }}
              >
                <div
                  style={{
                    width: 250,
                    color: "#15325F",
                    marginRight: 150,
                    // marginTop: 25,
                  }}
                >
                  <h2 className={classes.header_text}>Send Report:</h2>
                  <div style={{ width: 250, color: "#15325F", marginLeft: 10 }}>
                    <FormControl
                      fullWidth
                      disabled={
                        reportType === "enhanced_camera_health_report"
                          ? true
                          : false
                      }
                    >
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={
                          reportType === "enhanced_camera_health_report"
                            ? "daily"
                            : selectedPeriodValue
                        }
                        label="Age"
                        onChange={handlePeriodChange}
                      >
                        <MenuItem value={"daily"}>daily</MenuItem>
                        <MenuItem value={"weekly"}>weekly</MenuItem>
                        <MenuItem value={"monthly"}>monthly</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
                {selectedPeriodValue === "daily" && (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ marginTop: 40, color: "#15325F" }}>
                      <h2 className={classes.header_text}>
                        Report Time (Hr : Min)
                      </h2>
                      {/* <div style={{ marginLeft: 20 }}> */}
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-around",
                          marginRight: 100,
                          paddingRight: 20,
                        }}
                      >
                        <div
                          style={{
                            marginLeft: 20,
                            display: "flex",
                            alignItems: "center",
                            padding: 10,
                          }}
                        >
                          <FormControl fullWidth>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={selectedHour}
                              label="Age"
                              onChange={(e) => {
                                setSelectedHour(e.target.value);
                              }}
                            >
                              {hrs_list.map((hr) => (
                                <MenuItem value={hr}>{hr}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          &nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;
                          <FormControl fullWidth>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={selectedMinutes}
                              label="Age"
                              onChange={(e) => {
                                setSelectedMinutes(e.target.value);
                              }}
                            >
                              {min_list.map((min) => (
                                <MenuItem value={min}>{min}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div
                        style={{
                          color: "#15325F",
                          marginRight: 150,
                          marginTop: 30,
                          marginLeft: 30,
                        }}
                      >
                        <h2 className={classes.header_text}>Time Zone</h2>
                        <div
                          style={{
                            width: 250,
                            color: "#15325F",
                            marginLeft: 10,
                          }}
                        >
                          <FormControl fullWidth>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={zone}
                              label="Report Type"
                              onChange={(e) => setZone(e.target.value)}
                            >
                              {time_zones.map((zone) => (
                                <MenuItem value={zone}>{zone}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {selectedPeriodValue === "monthly" && (
                  <div>
                    <div
                      style={{
                        color: "#15325F",
                        marginTop: 40,
                      }}
                    >
                      <h2 className={classes.header_text}>Time Zone</h2>
                      <div
                        style={{
                          width: 250,
                          color: "#15325F",
                          marginLeft: 10,
                        }}
                      >
                        <FormControl fullWidth>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={zone}
                            label="Report Type"
                            onChange={(e) => setZone(e.target.value)}
                          >
                            {time_zones.map((zone) => (
                              <MenuItem value={zone}>{zone}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  </div>
                )}

                {selectedPeriodValue === "weekly" && (
                  <div style={{ marginTop: 40, color: "#15325F" }}>
                    <h2 className={classes.header_text}>
                      End day of the week:
                    </h2>
                    <div style={{ marginLeft: 5 }}>
                      <ButtonGroup
                        color="primary"
                        aria-label="outlined primary button group"
                      >
                        <Button
                          variant={day.sun ? "contained" : "outlined"}
                          onClick={() => {
                            handleChangeDay("sun");
                          }}
                        >
                          Sun
                        </Button>
                        <Button
                          variant={day.mon ? "contained" : "outlined"}
                          onClick={() => {
                            handleChangeDay("mon");
                          }}
                        >
                          Mon
                        </Button>
                        <Button
                          variant={day.tue ? "contained" : "outlined"}
                          onClick={() => {
                            handleChangeDay("tue");
                          }}
                        >
                          Tue
                        </Button>
                        <Button
                          variant={day.wed ? "contained" : "outlined"}
                          onClick={() => {
                            handleChangeDay("wed");
                          }}
                        >
                          Wed
                        </Button>
                        <Button
                          variant={day.thur ? "contained" : "outlined"}
                          onClick={() => {
                            handleChangeDay("thur");
                          }}
                        >
                          Thur
                        </Button>
                        <Button
                          variant={day.fri ? "contained" : "outlined"}
                          onClick={() => {
                            handleChangeDay("fri");
                          }}
                        >
                          Fri
                        </Button>
                        <Button
                          variant={day.sat ? "contained" : "outlined"}
                          onClick={() => {
                            handleChangeDay("sat");
                          }}
                        >
                          Sat
                        </Button>
                      </ButtonGroup>
                    </div>
                    {/* END TIME OF THE DAY */}
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ marginTop: 40, color: "#15325F" }}>
                        <h2 className={classes.header_text}>
                          Report Time (Hr : Min)
                        </h2>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-around",
                            marginRight: 100,
                            paddingRight: 20,
                          }}
                        >
                          <div
                            style={{
                              marginLeft: 20,
                              display: "flex",
                              alignItems: "center",
                              padding: 10,
                            }}
                          >
                            <FormControl fullWidth>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectedHour}
                                label="Age"
                                onChange={(e) => {
                                  setSelectedHour(e.target.value);
                                }}
                              >
                                {hrs_list.map((hr) => (
                                  <MenuItem value={hr}>{hr}</MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            &nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;
                            <FormControl fullWidth>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectedMinutes}
                                label="Age"
                                onChange={(e) => {
                                  setSelectedMinutes(e.target.value);
                                }}
                              >
                                {min_list.map((min) => (
                                  <MenuItem value={min}>{min}</MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div
                          style={{
                            color: "#15325F",
                            marginRight: 150,
                            marginTop: 30,
                            marginLeft: 30,
                          }}
                        >
                          <h2 className={classes.header_text}>Time Zone</h2>
                          <div
                            style={{
                              width: 250,
                              color: "#15325F",
                              marginLeft: 10,
                            }}
                          >
                            <FormControl fullWidth>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={zone}
                                label="Report Type"
                                onChange={(e) => setZone(e.target.value)}
                              >
                                {time_zones.map((zone) => (
                                  <MenuItem value={zone}>{zone}</MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {reportType !== "site_health" && (
                <>
                  {reportType !== "site_activity_consolidated" &&
                    reportType !== "site_activity_group" &&
                    reportType !== "idle_camera_report" && (
                      <div style={{ display: "flex" }}>
                        {reportType !== "enhanced_camera_health_report" && (
                          <div>
                            {" "}
                            <FormControl>
                              <br />
                              <Typography className={classes.header_text}>
                                Include Escalated Events
                              </Typography>
                            </FormControl>
                            <FormControl>
                              <br />
                              <Checkbox
                                className={classes.form_component_checkbox}
                                // disabled={true}
                                color="primary"
                                icon={<CheckBoxOutlineBlankIcon />}
                                checkedIcon={<CheckBoxIcon />}
                                checked={escalatedEvents}
                                onChange={(e) => {
                                  setescalatedEvents(e.target.checked);
                                }}
                              />
                            </FormControl>
                          </div>
                        )}
                      </div>
                    )}
                  {reportType !== "enhanced_camera_health_report" && (
                    <div style={{ display: "flex" }}>
                      <FormControl>
                        <br />
                        <Typography className={classes.header_text}>
                          Include CSV File
                        </Typography>
                        <br />
                      </FormControl>
                      <FormControl>
                        <br />
                        <Checkbox
                          className={classes.form_component_checkbox}
                          color="primary"
                          icon={<CheckBoxOutlineBlankIcon />}
                          checkedIcon={<CheckBoxIcon />}
                          checked={inc_csv}
                          onChange={(e) => {
                            setIncCSV(e.target.checked);
                          }}
                        />
                      </FormControl>
                    </div>
                  )}
                </>
              )}
              {/* TABLE CONTENT */}
              {reportType === "site_activity_consolidated" && (
                <>
                  <div
                    className={classes.button_container_small3}
                    style={{ marginLeft: 40 }}
                  >
                    <Button
                      variant="contained"
                      className={`${classes.cancel_button2} {$classes.bold_text}`}
                      onClick={unSelectAllHandler}
                    >
                      unSelect All
                    </Button>
                    <Button
                      style={{ marginRight: 10 }}
                      variant="contained"
                      className={classes.mute_button}
                      onClick={selectAllHandler}
                    >
                      Select All
                    </Button>
                  </div>
                  <div
                    style={{
                      boxShadow: "0 2px 6px rgba(0,0,0,0.3)",
                      borderRadius: 15,
                      minHeight: "fit-content",
                      maxHeight: 250,
                      overflowY: "scroll",
                      backgroundColor: "white",
                      marginLeft: 10,
                      marginRight: 20,
                      marginBottom: 35,
                      width: 850,
                    }}
                  >
                    {!loadingSites ? (
                      <TableContainer component={Paper}>
                        <Table size="small" stickyHeader>
                          <TableHead>
                            <TableRow>
                              <TableCell>
                                <span
                                  style={{
                                    fontSize: 13,
                                    color: "#616161",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Site ID
                                </span>
                              </TableCell>
                              <TableCell>
                                <span
                                  style={{
                                    fontSize: 13,
                                    color: "#616161",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Group
                                </span>
                              </TableCell>
                              <TableCell>
                                <span
                                  style={{
                                    fontSize: 13,
                                    color: "#616161",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Site Name
                                </span>
                              </TableCell>
                              <TableCell>
                                <span
                                  style={{
                                    fontSize: 13,
                                    color: "#616161",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Product
                                </span>
                              </TableCell>
                              <TableCell>
                                <span
                                  style={{
                                    fontSize: 13,
                                    color: "#616161",
                                    fontWeight: "bold",
                                    display: "flex",
                                  }}
                                >
                                  Select
                                </span>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>{!loading && sites_display}</TableBody>
                        </Table>
                      </TableContainer>
                    ) : (
                      <div
                        style={{
                          alignSelf: "center",
                          marginLeft: 400,
                          padding: 10,
                        }}
                      >
                        <Spinner />
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              color="primary"
              className={classes.cancel_button2}
            >
              Close
            </Button>
            <Button
              onClick={onSubmitHandler}
              color="primary"
              className={classes.mute_button}
            >
              Update
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
};

export default ReportEdit;
